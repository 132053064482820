<template>
  <div>
      <TransformationComponent />
  </div>
</template>

<script>
  import TransformationComponent from '../components/TransformationComponent.vue'
  export default {
    name: 'Home',

    components: {
        TransformationComponent
    },
  }
</script>
